
import { inputFields } from './inputfields';


export interface Checklist {
  response: string
  dialogType: string
  fields: inputFields[]
}

export interface createResponse {
  response: string,
  errorMessage?: string
}

export interface checklistItem {
  fieldName: string,
  fieldType: string,
  protected: string,
  value: string

}
export interface AvailableUsers {
  active: string,
  displayName: string,
  emailAddress: string,
  firstName: string,
  fkCustomerID: string,
  fkWorkstationID: string,
  language: string,
  lastName: string,
  userType: string,
  userID: string,
  timeScheme: string,
  role: string,
  phoneNumber: string,
  password: string,
  notificationMedium: string,
  normHours: string,
}