<!-- <div class="row mb-2 menublue titleBar urenRegistratie w-100">
		<div class="col-md-8">
				{{ "HOME.AGENDA_BUTTON" | translate }}
	</div>
		<div class="col-md-4" (click)="open(startnow,'')"><i class="fa fa-play-circle"></i>
				{{ "HOME.STARTNOW_BUTTON" | translate }}
	</div>
</div> -->
<!-- <div class="d-flex  justify-content-between">
	<div class="p-2 bd-highlight">Flex item 1</div>
	<div class="p-2 justify-content-end">	
		<div class="col-sm-12 border-end border-secondary date  slider_focus"   (click)="openXl(pdfmodal)">
		<div  class="d-flex p-2">
			<i  aria-hidden="true" class="fa fa-bar-chart">
			</i>
			<div class="row">
			<span  style="color:white;"> Week {{week_number}}</span>	
			<span  style="color: white;"> {{Overall_Total}}</span>
		</div>	
		</div>
</div>
</div>
	
  </div> -->
<!-- <div class="container d-flex">
    <div class="col"
  </div> -->

  <div class="d-flex col-sm-12 ">
	<div class="d-flex col-sm-4 mb-2 navigation justify-content-start">
		<div class="col-sm-3 p-2" style="background-color: #24597A;" (click)="set_dates('0')"
			(touchend)="set_dates('0')">
			<div class="col-1 p-2 border-secondary d-flex ">
				<i class="fa left_arrow">&#xf053;</i>
			</div>
		</div>
		<div class=" col-sm-6 p-2 justify-content-center mt-3" (click)="columnss('0');Set_slidervalue(todayDetails)">
			<span class="
			textblue"> {{ "HOME.TODAY_LABEL" | translate }}</span>
		</div>
		<div class="col-sm-3 p-2 " style="background-color: #24597A;" (click)="set_dates('1')"
			(touchend)="set_dates('1')">
			<div class="col-1 p-2 d-flex justify-content-between me-auto">
				<i class="fa right_arrow">&#xf054;</i>
			</div>
		</div>
	</div>
	<div class="col-sm-8  p-2">
		<div class="col-sm-3 ms-auto border-secondary date test" (click)="openXl(pdfmodal)"
			(touchend)="openXl(pdfmodal)">
			<div class="d-flex p-2"><i aria-hidden="true" class="fa fa-bar-chart"></i>
				<div class="row">
					<span style="color:white;"> Week {{week_number}}</span>
					<span style="color: white;"> {{Overall_Total}}</span>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="col-12">
	<div class="d-flex flex-nowrap border border-1 border-secondary">
		<!-- <div class="col-1 p-2 border-end border-secondary d-flex " (click)="set_dates('0')" style="background-color: #24597A;">
		<i   class="fa left_arrow">&#xf053;</i>
	   </div> -->
		<div class="col-sm border-end border-secondary date boxHeight" *ngFor="let item of slider_values" [ngClass]="{
		slider_focus: item?.focus == true  ,grey_focus: item?.today == true}" (click)="Set_slidervalue(item)"
			(touchend)="Set_slidervalue(item)">
			<div class="row">
				<span class="lang_day p-1" [ngClass]="{
					activeDay: item?.focus == true }"> {{item.lang_day}}</span>
				<span class="lang_name p-1" [ngClass]="{
					activeDay: item?.focus == true }">{{item.name}}</span>
			</div>
			<span class="total_hour p-1" [ngClass]="{
				activeDay: item?.focus == true }">{{item.total}}</span>
		</div>
	</div>
	<daypilot-calendar [config]="config" #calendar class="cal" [events]="events"></daypilot-calendar>
</div>

<ng-template #content let-modal>
	<div class="modal-header">
		<h2 class="modal-title" id="modal-basic-title" style="color: #24597a;">
			{{ "HOME.TIMESCHEDULING" | translate }}
		</h2>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body eventForm">
		<form [formGroup]="registrationForm" novalidate class="d-flex flex-column m-auto">
			<div class=" form-group row ">
				<div class="d-flex justify-content-end mx-2">
					<div class="col-sm-3">
						<div class="form-check form-check-inline">

							<input class="form-check-input borderInput" type="radio" name="urensoortRadio"
								value="project" (click)="setmodal_flag('1','')"><label class="form-check-label"
								for="urensoortRadio1">Project</label>
						</div>
					</div>
					<div class="col-sm-5">
						<div class="form-check  form-check-inline">
							<input class="form-check-input borderInput" type="radio" name="urensoortRadio"
								value="overig" checked="" (click)="setmodal_flag('2','')"><label
								class="form-check-label" for="urensoortRadio2">{{
								"HOME.OTHER_HOURS" | translate }}</label>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group row option-project" [hidden]="popup_flag">
				<label class="col-sm-4 col-form-label">Project:</label>
				<div class="col-sm-8">

					<input type="text" class="form-control borderInput" #projectInput placeholder="{{input_desc}}"
						name="project" (keyup)="getinput()"
						[ngClass]="{ 'is-invalid':input_item == false ,'active':input_item != false }"
						formControlName="project">

					<div class="row" *ngIf="customerID === '3'">
						<div class="col-md-10">

						</div>
						<div class="col-md-2">
							<div class="col-md-2">
								<select formControlName="projectExtention" [(ngModel)]="pedefaultvalue"
									class="form-select borderInput  selecttag3 " name="" id="">
									<option *ngFor="let list of projectExtentionValues" value="{{list}}">{{list}}
									</option>
								</select>
							</div>
						</div>


					</div>

				</div>
			</div>
			<div class="form-group row" style="display: flex;" *ngIf="!popup_flag && allow_greensubcat">
				<label class="col-sm-4 col-form-label">{{ "HOME.CATEGORIE_LABEL" | translate }}</label>
				<div class="col-sm-8">
					<select class="form-select borderInput" (change)='setFormFields(val.value,"add")' #val
						formControlName="categorie" (change)="Set_CategorieValue($event)"
						[(ngModel)]="Selected_Catgorie">
						<option *ngFor="let item of green_categories" [value]=item.value>
							{{ (item.languageKey) ? ("HOME."+item?.languageKey | translate): item?.text}}</option>
					</select>
				</div>
			</div>
			<ng-container *ngIf="!popup_flag">
				<div class="form-group row" *ngFor="let a of placeholder;let i=index">
					<label class="col-sm-4 col-form-label">{{ "HOME.EXTRA_LABEL" | translate }}</label>
					<div class="col-sm-8">
						<input type="text" class="form-control borderInput" [formControlName]="a" placeholder="{{a}}">
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="!popup_flag">
				<div class="form-group row " *ngIf="dropdown_values?.length > 0">
					<label class="col-sm-4 col-form-label">{{ "HOME.EXTRA_LABEL" | translate }}</label>
					<div class="col-sm-8">
						<select class="form-select borderInput" formControlName="mail"
							[(ngModel)]="defaultDropDownValues" aria-label=".form-select-sm example">
							<option *ngFor="let item of dropdown_values" value={{item}}>{{item}}</option>
						</select>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="!popup_flag">
				<div class="form-group row option-overig" style="display: flex;">
					<label class="col-sm-4 col-form-label">{{ "HOME.REMARK_LBEL" | translate }}</label>
					<div class="col-sm-8">
						<input class="form-control borderInput" type="text" placeholder="" formControlName="remark">
					</div>
				</div>
			</ng-container>
			<div class="form-group row " style="display: flex;" *ngIf="popup_flag">
				<label class="col-sm-4 col-form-label">{{ "HOME.KIND" | translate }}</label>
				<div class="col-sm-8">
					<select class="form-control borderInput" id="inputUrenSoort" formControlName="event"
						(change)="SetKind_dropdown($event)" [(ngModel)]="kind_value">
						<option *ngFor="let item of dropdown" [value]=item.value>{{item.text}} </option>
					</select>
				</div>
			</div>
			<!-- <div class="form-group row option-overig" style="display: flex;" *ngIf="!popup_flag">
				<label class="col-sm-4 col-form-label">{{ "HOME.REMARK_LBEL" | translate }}</label>
				<div class="col-sm-8">
					<input class="form-control" type="text"  placeholder="" formControlName="remark">
				</div>
			</div> -->
			<div class="form-group row option-overig" style="display: flex;" *ngIf="popup_flag">
				<label class="col-sm-4 col-form-label">{{ "HOME.COMMENTS_LABEL" | translate }}</label>
				<div class="col-sm-8">
					<input class="form-control borderInput" type="text" placeholder="" formControlName="note">
				</div>
			</div>
			<div class="form-group row">
				<label class="col-sm-4 col-form-label">{{ "HOME.FROM_LABEL" | translate }}</label>
				<div class="col-sm-4">
					<div class="d-flex">
						<input type="text" pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}" placeholder="dd-mm-yyyy"
							class="form-control input_fed_worked_dtl  borderInput" formControlName="from_date">
					</div>
				</div>
				<div class="col-sm-4">
					<div class="d-flex working_hours_dtl_main ">
						<div class="d-flex working_hours_dtl gap-1">
							<input type="text" class="form-control borderInput input_fiedl" #startHourTime (change)="startTimerChange(startHourTime.value,'hour')" placeholder="HH"
								[ngModel]="this.fromStartTime?.split(':')[0]" [ngModelOptions]="{ standalone: true }"
								[ngClass]="{ 'is-invalid': invalidTime == true }">
							<div class="d-flex flex-column justify-content-between">
								<i class="bi bi-plus-lg  fa-solid-clr" (click)="addAndRemoveStartHours('add')"></i>
								<i class="bi bi-dash fa-solid-clr" (click)="addAndRemoveStartHours('remove')"></i>
							</div>
						</div>
						<div class="d-flex working_hours_dtl gap-1">
							<input type="text" #startMinTime (change)="startTimerChange(startMinTime.value,'min')"  [ngModel]="this.fromStartTime?.split(':')[1]"
								[ngModelOptions]="{ standalone: true }" class="form-control borderInput input_fiedl"
								placeholder="MM">
							<div class="d-flex flex-column justify-content-between">
								<i class="bi bi-plus-lg  fa-solid-clr" (click)="addAndRemoveStartMinutes('add')"></i>
								<i class="bi bi-dash fa-solid-clr" (click)="addAndRemoveStartMinutes('remove')"></i>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="col-sm-4">
					<div class="d-flex gap-1">
						<div class="d-flex flex-column justify-content-between">
							<i class="fa fa-plus-square-o" (click)="addFromHour('registrationForm')"></i>
							<i class="fa fa-minus-square-o" (click)="removeFromHors('registrationForm')"></i>
						</div>
						<input type="text" id="fromtimeid" pattern="[0-9]{2}:[0-9]{2}" name="fromTime"
							class="form-control borderInput" formControlName="from_time" placeholder="HH:MM"
							[inputMask]="timeMask" [(ngModel)]="registrationForm.value.from_time">
						<div class="d-flex flex-column justify-content-between">
							<i class="fa fa-plus-square-o" (click)="addFromMinutes('registrationForm')"></i>
							<i class="fa fa-minus-square-o" (click)="removeFromMinutes('registrationForm')"></i>
						</div>
					</div>
				</div> -->
			</div>
			<!-- duration -->
			<div class="form-group row">
				<label class="col-sm-4 col-form-label">{{ "HOME.DURATION" | translate }}:</label>
				<div class="col-sm-4">

				</div>
				<div class="col-sm-4">

					<div class="d-flex working_hours_dtl_main ">
						<div class="d-flex working_hours_dtl gap-1">
							<input type="text" class="form-control borderInput input_fiedl" #durationHourTime (change)="setDurationTime(durationHourTime.value,'hour')" placeholder="HH"
								[ngModel]="this.duarionTime.split(':')[0]" [ngModelOptions]="{ standalone: true }"
								[ngClass]="{ 'is-invalid': invalidTime == true }">
							<div class="d-flex flex-column justify-content-between">
								<i class="bi bi-plus-lg  fa-solid-clr" (click)="addAndRemoveDurationHours('add')"></i>
								<i class="bi bi-dash fa-solid-clr" (click)="addAndRemoveDurationHours('remove')"></i>
							</div>
						</div>
						<div class="d-flex working_hours_dtl gap-1">
							<input type="text" [ngModel]="this.duarionTime.split(':')[1]" #durationMinTime (change)="setDurationTime(durationMinTime.value,'min')"
								[ngModelOptions]="{ standalone: true }" class="form-control borderInput input_fiedl"
								placeholder="MM">
							<div class="d-flex flex-column justify-content-between">
								<i class="bi bi-plus-lg  fa-solid-clr" (click)="addAndRemoveDurationMinutes('add')"></i>
								<i class="bi bi-dash fa-solid-clr" (click)="addAndRemoveDurationMinutes('remove')"></i>
							</div>
						</div>
					</div>
					<!-- <div class="d-flex gap-1">
						<div class="d-flex flex-column justify-content-between">
							<i class="fa fa-plus-square-o" (click)="addRemoveHours('add')"></i>
							<i class="fa fa-minus-square-o" (click)="addRemoveHours('remove')"></i>
						</div>
						<input type="text" id="fromtimeid" pattern="[0-9]{2}:[0-9]{2}" name="fromTime"
							class="form-control borderInput" placeholder="HH:MM" value="{{duarionTime}}"
							[inputMask]="timeMask">
						<div class="d-flex flex-column justify-content-between">
							<i class="fa fa-plus-square-o" (click)="addRemoveMinutes('add')"></i>
							<i class="fa fa-minus-square-o" (click)="addRemoveMinutes('remove')"></i>
						</div>
					</div> -->
				</div>
			</div>
			<!-- g -->
			<div class="form-group row">
				<label class="col-sm-4 col-form-label">{{ "HOME.UNTIL_LABEL" | translate }}</label>
				<div class="col-sm-4">
					<input type="text" class="form-control borderInput input_fed_worked_dtl"
						[ngClass]="{ 'is-invalid': invalidDate == true }" placeholder="dd-mm-yyyy"
						formControlName="to_date">
				</div>
				<div class="col-sm-4">
					<div class="d-flex working_hours_dtl_main ">
						<div class="d-flex working_hours_dtl gap-1">
							<input type="text" #hrvalues (change)="endTimerChange(hrvalues.value,'hour')" class="form-control borderInput input_fiedl" placeholder="HH"
								[ngModel]="this.toEndTime?.split(':')[0]" [ngModelOptions]="{ standalone: true }"
								[ngClass]="{ 'is-invalid': invalidTime == true }">

							<div class="d-flex flex-column justify-content-between">
								<i class="bi bi-plus-lg  fa-solid-clr" (click)="addAndRemoveEndHours('add')"></i>
								<i class="bi bi-dash fa-solid-clr" (click)="addAndRemoveEndHours('remove')"></i>
								<!-- <i class="fa fa-plus-square-o" (click)="addToHour('registrationForm')"></i>
									<i class="fa fa-minus-square-o" (click)="removeToHors('registrationForm')"></i> -->
							</div>
							<!-- <div class="d-flex flex-column justify-content-between">
								<i class="fa fa-plus-square-o" (click)="addToMinutes('registrationForm')"></i>
								<i class="fa fa-minus-square-o" (click)="removeToMinutes('registrationForm')"></i>
	
							</div> -->
						</div>
						<div class="d-flex working_hours_dtl gap-1">
							<input type="text" [ngModel]="this.toEndTime?.split(':')[1]" #mrvalues (change)="endTimerChange(mrvalues.value,'min')"
								[ngModelOptions]="{ standalone: true }" class="form-control borderInput input_fiedl"
								placeholder="MM">
							<div class="d-flex flex-column justify-content-between">
								<i class="bi bi-plus-lg  fa-solid-clr" (click)="addAndRemoveEndMinutes('add')"></i>
								<i class="bi bi-dash fa-solid-clr" (click)="addAndRemoveEndMinutes('remove')"></i>
								<!-- <i class="fa fa-plus-square-o" (click)="addToHour('registrationForm')"></i>
									<i class="fa fa-minus-square-o" (click)="removeToHors('registrationForm')"></i> -->
							</div>
							<!-- <div class="d-flex flex-column justify-content-between">
								<i class="fa fa-plus-square-o" (click)="addToMinutes('registrationForm')"></i>
								<i class="fa fa-minus-square-o" (click)="removeToMinutes('registrationForm')"></i>
	
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary confirm" (click)="setValiadtion(submitButton)"
			(touchend)="setValiadtion(submitButton)" #submitButton>
			{{ "HOME.CONFIRMBUTTON" | translate }}
		</button>
		<button type="button" class="btn btn-success reset" (click)="modal.close('Save click')">
			{{ "HOME.CANCELBUTTON" | translate }}
		</button>
	</div>
</ng-template>


<ng-template #startnow let-modal>
	<div class="modal-header">
		<h2 class="modal-title" id="modal-basic-title">
			{{ "HOME.HOUR_TYPE" | translate }}
		</h2>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<form [formGroup]="statusForm" novalidate class="d-flex flex-column m-auto">
			<div class="form-group row " style="display: flex;">
				<label class="col-sm-4 col-form-label"> {{ "HOME.KIND_LABEL" | translate }}</label>
				<div class="col-sm-8">
					<select class="form-control" id="inputUrenSoort" (change)="SetKind_dropdown($event)"
						formControlName="event" [(ngModel)]="kind_value">
						<option *ngFor="let item of dropdown" [value]=item.value>{{item.text}}</option>
					</select>
				</div>
			</div>
			<div class="form-group row option-overig" style="display: flex;">
				<label class="col-sm-4 col-form-label"> {{ "HOME.REMARK_LBEL" | translate }}</label>
				<div class="col-sm-8">
					<input type="text" class="form-control" placeholder="" formControlName="note">
				</div>
			</div>

		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="create_darkblue(content_green,submitButton)"
			(touchend)="create_darkblue(content_green,submitButton)" #submitButton>
			{{ "HOME.CONFIRMBUTTON" | translate }}
		</button>
		<button type="button" class="btn btn-success reset" (click)="modal.close('Save click')">
			{{ "HOME.CANCELBUTTON" | translate }}
		</button>
	</div>
</ng-template>

<ng-template #update let-modal>
	<div class="modal-header">
		<h2 class="modal-title" id="modal-basic-title" style="color: #24597a;">
			{{ "HOME.TIMESCHEDULING" | translate }}
		</h2>
		<button type="button" class="btn-close" aria-label="Close"
			(click)="modal.dismiss('Cross click');clear_updateFields()"
			(touchend)="modal.dismiss('Cross click');clear_updateFields()"></button>
	</div>
	<div class="modal-body eventForm">
		<form [formGroup]="updateForm" novalidate class="d-flex flex-column m-auto">
			<div class="form-group row option-project" *ngIf="!updatepopup_flag">
				<label class="col-sm-4 col-form-label">Project:</label>
				<div class="col-sm-8">
					<input type="text" class="form-control borderInput" formControlName="project">
					<div class="row" *ngIf="customerID === '3'">
						<div class="col-md-10">

						</div>
						<div class="col-md-2">
							<div class="col-md-2">
								<select formControlName="projectExtention" class="form-select borderInput  selecttag3 "
									name="" id="">
									<option *ngFor="let list of projectExtentionValues" value="{{list}}">{{list}}
									</option>
								</select>
							</div>
						</div>


					</div>
				</div>
			</div>
			<div class="form-group row " style="display: flex;" *ngIf="!updatepopup_flag && allow_greensubcat">
				<label class="col-sm-4 col-form-label">{{ "HOME.CATEGORIE_LABEL" | translate }}</label>
				<div class="col-sm-8">
					<select class="form-select borderInput" formControlName="categorie"
						(change)='setFormFields(val.value,"update")' #val>
						<option *ngFor="let item of green_categories" [value]=item.value> {{ (item.languageKey) ?
							("HOME."+item?.languageKey | translate): item?.text}}</option>
					</select>
				</div>
			</div>
			<div class="form-group row " *ngFor="let a of placeholder;let i=index">
				<label class="col-sm-4 col-form-label">{{ "HOME.EXTRA_LABEL" | translate }}</label>
				<div class="col-sm-8">
					<input type="text" class="form-control borderInput" [formControlName]="a" [(ngModel)]="ExtraValue"
						placeholder="{{a}}">
				</div>
			</div>
			<div class="form-group row" *ngIf="dropdown_values?.length > 0 && !updatepopup_flag && allow_greensubcat">
				<label class="col-sm-4 col-form-label">{{ "HOME.EXTRA_LABEL" | translate }}</label>
				<div class="col-sm-8">
					<select class="form-select borderInput" formControlName="mail" aria-label=".form-select-sm example">
						<option *ngFor="let item of dropdown_values" value={{item}}>{{item}}</option>
					</select>
				</div>
			</div>
			<div class="form-group row " *ngIf="!updatepopup_flag">
				<label class="col-sm-4 col-form-label">{{ "HOME.REMARK_LBEL" | translate }}</label>
				<div class="col-sm-8">
					<input class="form-control borderInput" placeholder="" formControlName="remark">
				</div>
			</div>
			<div class="form-group row " style="display: flex;" *ngIf="updatepopup_flag">
				<label class="col-sm-4 col-form-label">{{ "HOME.KIND" | translate }}</label>
				<div class="col-sm-8">
					<select class="form-control borderInput" id="inputUrenSoort" formControlName="event">
						<option *ngFor="let item of dropdown" [value]=item.value>{{item.text}}</option>
					</select>
				</div>
			</div>
			<!-- <div class="form-group row option-overig" style="display: flex;" *ngIf="!updatepopup_flag">
				<label class="col-sm-4 col-form-label">{{ "HOME.REMARK_LBEL" | translate }}</label>
				<div class="col-sm-8">
					<input class="form-control" placeholder="" formControlName="remark">
				</div>
			</div> -->
			<div class="form-group row option-overig" style="display: flex;" *ngIf=" updatepopup_flag">
				<label class="col-sm-4 col-form-label">{{ "HOME.COMMENTS_LABEL" | translate }}</label>
				<div class="col-sm-8">
					<input class="form-control borderInput" id="inputRemarks" placeholder="" formControlName="note">
				</div>
			</div>

			<div class="form-group row">
				<label class="col-sm-4 col-form-label">{{ "HOME.FROM_LABEL" | translate }}</label>
				<div class="col-sm-4">
					<input type="text" pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}" placeholder="dd-mm-yyyy"
						class="form-control borderInput" formControlName="from_date">
				</div>
				<div class="col-sm-4">
					<div class="d-flex working_hours_dtl_main ">
						<div class="d-flex working_hours_dtl gap-1">
							<input type="text"  #startHourTime (change)="startTimerChange(startHourTime.value,'hour')" class="form-control borderInput input_fiedl" placeholder="HH"
								[ngModel]="this.fromStartTime?.split(':')[0]" [ngModelOptions]="{ standalone: true }"
								[ngClass]="{ 'is-invalid': invalidTime == true }">

							<div class="d-flex flex-column justify-content-between">
								<i class="bi bi-plus-lg  fa-solid-clr" (click)="addAndRemoveStartHours('add')"></i>
								<i class="bi bi-dash fa-solid-clr" (click)="addAndRemoveStartHours('remove')"></i>
								<!-- <i class="fa fa-plus-square-o" (click)="addToHour('registrationForm')"></i>
									<i class="fa fa-minus-square-o" (click)="removeToHors('registrationForm')"></i> -->
							</div>
							<!-- <div class="d-flex flex-column justify-content-between">
								<i class="fa fa-plus-square-o" (click)="addToMinutes('registrationForm')"></i>
								<i class="fa fa-minus-square-o" (click)="removeToMinutes('registrationForm')"></i>
	
							</div> -->
						</div>
						<div class="d-flex working_hours_dtl gap-1">
							<input type="text" [ngModel]="this.fromStartTime?.split(':')[1]"
								[ngModelOptions]="{ standalone: true }" #startminTime (change)="startTimerChange(startminTime.value,'min')" class="form-control borderInput input_fiedl"
								placeholder="MM">

							<div class="d-flex flex-column justify-content-between">
								<i class="bi bi-plus-lg  fa-solid-clr" (click)="addAndRemoveStartMinutes('add')"></i>
								<i class="bi bi-dash fa-solid-clr" (click)="addAndRemoveStartMinutes('remove')"></i>
								<!-- <i class="fa fa-plus-square-o" (click)="addToHour('registrationForm')"></i>
									<i class="fa fa-minus-square-o" (click)="removeToHors('registrationForm')"></i> -->
							</div>
							<!-- <div class="d-flex flex-column justify-content-between">
								<i class="fa fa-plus-square-o" (click)="addToMinutes('registrationForm')"></i>
								<i class="fa fa-minus-square-o" (click)="removeToMinutes('registrationForm')"></i>
	
							</div> -->
						</div>
					</div>
				</div>
				<!-- <div class="col-sm-4">
					<div class="d-flex  gap-1">
						<div class="d-flex flex-column justify-content-between">
							<i class="fa fa-plus-square-o" (click)="addFromHour('updateForm')"></i>
							<i class="fa fa-minus-square-o" (click)="removeFromHors('updateForm')"></i>
						</div>
						<input type="text" pattern="[0-9]{2}:[0-9]{2}" placeholder="HH:MM" [inputMask]="timeMask"
							[(ngModel)]="this.updateForm.value.from_time" class="form-control borderInput"
							formControlName="from_time">
						<div class="d-flex flex-column justify-content-between">
							<i class="fa fa-plus-square-o" (click)="addFromMinutes('updateForm')"></i>
							<i class="fa fa-minus-square-o" (click)="removeFromMinutes('updateForm')"></i>

						</div>
					</div>
				</div> -->
			</div>
			<!-- duration -->
			<div class="form-group row">
				<label class="col-sm-4 col-form-label">{{ "HOME.DURATION" | translate }}:</label>
				<div class="col-sm-4">

				</div>
				<div class="col-sm-4">
					<div class="d-flex working_hours_dtl_main ">
						<div class="d-flex working_hours_dtl gap-1">
							<input type="text" class="form-control borderInput input_fiedl" #durationhourTime (change)="setDurationTime(durationhourTime.value,'hour')" placeholder="HH"
								[ngModel]="this.duarionTime.split(':')[0]" [ngModelOptions]="{ standalone: true }"
								[ngClass]="{ 'is-invalid': invalidTime == true }">
							<div class="d-flex flex-column justify-content-between">
								<i class="bi bi-plus-lg  fa-solid-clr" (click)="addAndRemoveDurationHours('add')"></i>
								<i class="bi bi-dash fa-solid-clr" (click)="addAndRemoveDurationHours('remove')"></i>
							</div>
						</div>
						<div class="d-flex working_hours_dtl gap-1">
							<input type="text" #durationminTime (change)="setDurationTime(durationminTime.value,'min')" [ngModel]="this.duarionTime.split(':')[1]"
								[ngModelOptions]="{ standalone: true }" class="form-control borderInput input_fiedl"
								placeholder="MM">
							<div class="d-flex flex-column justify-content-between">
								<i class="bi bi-plus-lg  fa-solid-clr" (click)="addAndRemoveDurationMinutes('add')"></i>
								<i class="bi bi-dash fa-solid-clr" (click)="addAndRemoveDurationMinutes('remove')"></i>
							</div>
						</div>
					</div>
					<!-- <div class="d-flex gap-1">
						<div class="d-flex flex-column justify-content-between">
							<i class="fa fa-plus-square-o" (click)="addRemoveHours('add')"></i>
							<i class="fa fa-minus-square-o" (click)="addRemoveHours('remove')"></i>
						</div>
						<input type="text" id="fromtimeid" pattern="[0-9]{2}:[0-9]{2}" name="fromTime"
							class="form-control borderInput" placeholder="HH:MM" value="{{duarionTime}}"
							[inputMask]="timeMask">
						<div class="d-flex flex-column justify-content-between">
							<i class="fa fa-plus-square-o" (click)="addRemoveMinutes('add')"></i>
							<i class="fa fa-minus-square-o" (click)="addRemoveMinutes('remove')"></i>
						</div>
					</div> -->
				</div>
			</div>
			<!-- g -->
			<div class="form-group row">
				<label class="col-sm-4 col-form-label">{{ "HOME.UNTIL_LABEL" | translate }}</label>
				<div class="col-sm-4">
					<input type="text" class="form-control borderInput"
						[ngClass]="{ 'is-invalid': invalidDate == true }" placeholder="dd-mm-yyyy"
						formControlName="to_date">
				</div>
				<div class="col-sm-4">
					<div class="d-flex working_hours_dtl_main ">
						<div class="d-flex working_hours_dtl gap-1">
							<input type="text" class="form-control borderInput input_fiedl" #huvalues (change)="endTimerChange(huvalues.value,'hour')" placeholder="HH"
								[ngModel]="this.toEndTime?.split(':')[0]" [ngModelOptions]="{ standalone: true }"
								[ngClass]="{ 'is-invalid': invalidTime == true }">

							<div class="d-flex flex-column justify-content-between">
								<i class="bi bi-plus-lg  fa-solid-clr" (click)="addAndRemoveEndHours('add')"></i>
								<i class="bi bi-dash fa-solid-clr" (click)="addAndRemoveEndHours('remove')"></i>
								<!-- <i class="fa fa-plus-square-o" (click)="addToHour('registrationForm')"></i>
									<i class="fa fa-minus-square-o" (click)="removeToHors('registrationForm')"></i> -->
							</div>
							<!-- <div class="d-flex flex-column justify-content-between">
								<i class="fa fa-plus-square-o" (click)="addToMinutes('registrationForm')"></i>
								<i class="fa fa-minus-square-o" (click)="removeToMinutes('registrationForm')"></i>
	
							</div> -->
						</div>
						<div class="d-flex working_hours_dtl gap-1">
							<input type="text" #muvalues (change)="endTimerChange(muvalues.value,'min')" [ngModel]="this.toEndTime?.split(':')[1]"
								[ngModelOptions]="{ standalone: true }" class="form-control borderInput input_fiedl"
								placeholder="MM">

							<div class="d-flex flex-column justify-content-between">
								<i class="bi bi-plus-lg  fa-solid-clr" (click)="addAndRemoveEndMinutes('add')"></i>
								<i class="bi bi-dash fa-solid-clr" (click)="addAndRemoveEndMinutes('remove')"></i>
								<!-- <i class="fa fa-plus-square-o" (click)="addToHour('registrationForm')"></i>
									<i class="fa fa-minus-square-o" (click)="removeToHors('registrationForm')"></i> -->
							</div>
							<!-- <div class="d-flex flex-column justify-content-between">
								<i class="fa fa-plus-square-o" (click)="addToMinutes('registrationForm')"></i>
								<i class="fa fa-minus-square-o" (click)="removeToMinutes('registrationForm')"></i>
	
							</div> -->
						</div>
					</div>
				</div>
				<!-- <div class="col-sm-4">
					<div class="d-flex  gap-1">
						<div class="d-flex flex-column justify-content-between">
							<i class="fa fa-plus-square-o" (click)="addToHour('updateForm')"></i>
							<i class="fa fa-minus-square-o" (click)="removeToHors('updateForm')"></i>
						</div>
						<input type="text" class="form-control borderInput" placeholder="HH:MM" [inputMask]="timeMask"
							pattern="[0-9]{2}:[0-9]{2}" [ngClass]="{ 'is-invalid': invalidTime == true }"
							[(ngModel)]="updateForm.value.to_time" formControlName="to_time">
						<div class="d-flex flex-column justify-content-between">
							<i class="fa fa-plus-square-o" (click)="addToMinutes('updateForm')"></i>
							<i class="fa fa-minus-square-o" (click)="removeToMinutes('updateForm')"></i>
						</div>
					</div>
				</div> -->
			</div>
			<div class="form-group row" *ngIf="show_checkbox">
				<div class="col-sm-1">
					<input type="checkbox" formControlName="delete_checkbox">
				</div>
				<div class="col-sm-10" style="color:red" (click)="setDeleteCheckbox()" (touchend)="setDeleteCheckbox()">
					{{ "HOME.CONFIRM_CLICK_AGAIN" | translate }}</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary confirm" (click)="setvaidationForUpdate(submitButton)"
			(touchend)="setvaidationForUpdate(submitButton)" #submitButton>
			{{ "HOME.CONFIRMBUTTON" | translate }}
		</button>
		<button type="button" class="btn btn-outline-danger confirm" style="display: initial;" (click)="deleteEvent()"
			(touchend)="deleteEvent()">{{
			"HOME.REMOVEBUTTON" | translate }}</button>
		<button type="button" class="btn btn-success reset" (click)="modal.close('Save click');clear_updateFields()"
			(touchend)="modal.dismiss('Cross click'); clear_updateFields()">
			{{ "HOME.CANCELBUTTON" | translate }}
		</button>
	</div>
</ng-template>


<ng-template #content_green let-modal>
	<div class="modal-header">
		<h2 class="modal-title" id="modal-basic-title">
			{{ "HOME.ACTIVITYTITTLE" | translate }}
		</h2>
		<button type="button" class="btn-close" aria-label="Close"
			(click)="modal.dismiss('Cross click'); clear_greendata()"
			(touchend)="modal.dismiss('Cross click'); clear_greendata()"></button>
	</div>
	<div class="modal-body">
		<p *ngIf="role == 'office'">
			{{ "HOME.ACTIVITYCONTENT_OFFICE" | translate }}
		</p>
		<p *ngIf="role == 'office'">
			{{ "HOME.ACTIVITY_QUES_OFFICE" | translate }} {{ green_eventdata?.orderID }}
			doen?
		</p>
		<p *ngIf="role != 'office'">
			{{ "HOME.ACTIVITYCONTENT" | translate }}
		</p>
		<p *ngIf="role != 'office'">
			{{ "HOME.ACTIVITY_QUES" | translate }} {{ green_eventdata.orderID }}
			{{ "HOME.ACTIVITY_SUBQUES" | translate }} {{ name }} ?
		</p>
		<form [formGroup]="activityForm" novalidate>
			<div class="form-check">
				<input class="form-check-input" type="radio" name="event" formControlName="event" value="close"
					id="flexRadioDefault1" />
				<label class="form-check-label" for="flexRadioDefault1">
					{{ "HOME.CLOSEBUTTON" | translate }}
				</label>
			</div>
			<div class="form-check">
				<input class="form-check-input" type="radio" name="event" formControlName="event" value="yellow"
					id="flexRadioDefault2" />
				<label class="form-check-label" for="flexRadioDefault2">
					{{ "HOME.PUT_YELLOW" | translate }}
				</label>
			</div>
			<div class="form-check">
				<input class="form-check-input" type="radio" name="event" formControlName="event" value="red"
					id="flexRadioDefault3" />
				<label class="form-check-label" for="flexRadioDefault3">
					{{ "HOME.PUT_RED" | translate }}
				</label>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="onSubmit_event(submitButton)"
			(touchend)="onSubmit_event(submitButton)" #submitButton>
			{{ "HOME.CONFIRMBUTTON" | translate }}
		</button>
		<button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click'); clear_greendata()"
			(touchend)="modal.close('Save click'); clear_greendata()">
			{{ "HOME.CANCELBUTTON" | translate }}
		</button>
	</div>
</ng-template>

<ng-template #pdfmodal let-modal>
	<div class="modal-header textblue">
		<h2 class="modal-title">{{ "HOME.WEEKLY_SUMMARY_LABEL" | translate }}</h2>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click');"></button>
	</div>
	<!-- generatePDF(divRef) -->
	<div class="modal-body textblue" id="print-paper">
		<div class="d-flex justify-content-start p-2 download">
			<button class="btn btn-primary" (click)="getPDF(submitButton)" (touchend)="getPDF(submitButton)"
				#submitButton>{{ "HOME.DOWNLOAD_PDF_LABEL" | translate
				}}</button>
			<!-- <a href={{getPDF()}}} >{{ "HOME.DOWNLOAD_PDF_LABEL" | translate
				}}</a> -->
		</div>
		<table class="table table-bordered textblue" #divRef>
			<thead>
				<tr>
					<th scope="col">{{ "HOME.PROJECT_OTHERHOURS_LABEL" | translate }}</th>
					<th *ngFor="let item of slider_values">{{item.tab_date}} </th>
					<th scope="col" class="grey">{{ "HOME.WEEK_TOTAL_LABEL" | translate }}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let data of Table_View;let i=index">
					<td *ngIf="data?.name === 'LOCATION'"> {{ "HOME."+data.name | translate }}</td>
					<td *ngIf="data?.name != 'Total per day' && data?.eventType == 'green'">Project {{data.orderID}} -
						{{data.name}}</td>
					<td *ngIf="data?.name != 'Total per day' && data?.eventType == 'darkblue'">{{data.name}}</td>
					<!-- <td *ngIf="data?.name != 'Total per day'">drop</td> -->
					<td *ngIf="data?.name == 'Total per day'" class="table-active textblue">{{
						"HOME.TOTAL_PER_DAY_LABEL" |
						translate }}</td>
					<ng-container *ngIf="data?.name !== 'LOCATION'">
						<td *ngFor="let items of data?.duration" [ngClass]="{
                grey: data?.name === 'Total per day'
              }">{{items}}</td>
					</ng-container>
					<ng-container *ngIf="data?.name === 'LOCATION'">
						<td *ngFor="let items of data.selectBoxArray;let i = index">
							<form [formGroup]="locationForm">
								<select class="form-select form-select-sm" [formControlName]="items.label"
									[(ngModel)]="items.selectedValue" (change)="updateLocation(i)"
									aria-label=".form-select-sm example">
									<option *ngFor="let item of items.item " value={{item}}>{{item}} </option>
								</select>
							</form>
						</td>
					</ng-container>
					<td class="grey">{{data.total}}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="modal-footer ">
		<button type="button" class="btn btn-light textblue reset" (click)="modal.close('Close click');">{{
			"HOME.CLOSEBUTTON" |
			translate }}</button>
	</div>
</ng-template>