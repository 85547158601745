import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { DataService } from 'src/services/dataservice.service'
import { LandingPageComponent } from '../landing-page/landing-page.component'
import { DashboardComponent } from 'src/usermodule/dashboard/dashboard.component';
import { ApiService } from 'src/services/apiservice.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModalConfig, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../environments/environment';
import { ChecklistModalComponent } from '../checklist-modal/checklist-modal.component';
import { Checklist } from '../../models/checklist';
import moment from 'moment';

@Component({
  selector: 'app-unplanned-down-time',
  templateUrl: './unplanned-down-time.component.html',
  styleUrls: ['./unplanned-down-time.component.scss']
})

export class UnplannedDownTimeComponent {
  @ViewChild('instructions') public modalComponent !: ChecklistModalComponent;
  @ViewChild("textboxmodal") private contentRef: TemplateRef<Object>;
  @ViewChild("popupformmodal") private popupmodal: TemplateRef<Object>;
  downtimeform: any;
  currentuser: any;
  categories: any;
  closeResult: string;
  dropdown_values: any = [];
  dropdownform: any;
  values: any = [];
  values_b: any = [];
  array: any = [];
  add_info_modal = false;
  eventDesc: any;
  customerID: any;
  placeholder: any = [];
  hidebutton: boolean;
  role: any;
  popupform: any;
  SelectedCategory: any;
  splitted_items: any = [];
  workstationid: string | null;
  RedEventID: any;
  checklist_flag: any;
  checkListItems: any;
  checkListFormGroup: any;
  active_order: any;
  firstIndexvalue: string;
  public autopopup = false;
  url: string = ''
  defaultDropdown: string = ''
  @Output() valueChange = new EventEmitter();
  dropDownvalues: any = [];

  constructor(private ChecklistModalComponent: ChecklistModalComponent,
    private config: NgbModalConfig, private ngxService: NgxUiLoaderService, private modalService: NgbModal, private toastrService: ToastrService, private apiservice: ApiService, public DashboardComponent: DashboardComponent, private router: Router, public fb: UntypedFormBuilder, private myService: DataService, public LandingPageComponent: LandingPageComponent) {
    this.currentuser = localStorage.getItem('current_user');
    this.currentuser = JSON.parse(this.currentuser);
    this.workstationid = localStorage.getItem("workstationID");
    this.customerID = localStorage.getItem('customerID');
    this.categories = localStorage.getItem("redcategories");
    this.categories = JSON.parse(this.categories);
    this.checklist_flag = localStorage.getItem("showchecklist");
    this.config.backdrop = 'static';
    this.config.keyboard = false;
    this.role = localStorage.getItem("role");
    let data: any = localStorage.getItem("active_order")
    data = JSON.parse(data as any);
    this.active_order = { orderID: data.orderID, mapping_field: data.mapping_field };
    this.dropdownform = this.fb.group({
      additional_info: ['', [Validators.required]],
    })
    this.downtimeform = this.fb.group({
      info: ['', [Validators.required]],
    });
    this.popupform = this.fb.group({
      mail: ['', []],
      email: ['', []],
      field: ['', []]
    })
  }


  async setFormFields(item: any) {
    this.DashboardComponent.disabledOrder = true;
    this.DashboardComponent.isShow = true;
    (this.DashboardComponent.intervall) ? clearInterval(this.DashboardComponent.intervall) : '';
    (this.DashboardComponent.countuptimer) ? clearInterval(this.DashboardComponent.countuptimer) : '';
    localStorage.removeItem(this.currentuser.userID + 'countDownDate');
    this.DashboardComponent.t = '00:00:00'
    this.SelectedCategory = item;
    this.DashboardComponent.ordercounter = '00:00:00';
    this.DashboardComponent.currentOrderColor = 'red';
    this.DashboardComponent.isShow = true;
    this.DashboardComponent.setIntervalCommmon()
    if (this.SelectedCategory.preventSkipExtraLevel == '1') {
      this.config.backdrop = 'static';
      this.config.keyboard = false;
    } else {
      this.config.backdrop = true;
      this.config.keyboard = true;
    }
    this.dropdown_values = [];
    this.placeholder = [];
    this.values_b = [];
    this.set_flag();
  }

  async setAutoPopupForm(dynamicExtraLevel: any) {
    let item = this.SelectedCategory;
    item.extraLevel = dynamicExtraLevel ? dynamicExtraLevel : this.SelectedCategory.extraLevel; this.dropdown_values = [];
    this.placeholder = [];
    this.values_b = [];
    await this.setCommon(item)
  }
  setCommon(item: any) {
    if (item.extraLevel != null && item.extraLevel != "") {
      if (item.extraLevel.includes('mailto') && item.extraLevel.includes('|')) {
        let mail_flag;
        this.splitted_items = item.extraLevel.split(';');
        this.firstIndexvalue = this.splitted_items;
        var format = /[~`!#$%^&*+=\[\]\\';,{}|\\":<>?<>]/g;
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            if (element.includes('|')) {
              let el = element.split('|')
              for (let i = 0; i < el.length; i++) {
                this.dropDownvalues.push(el[i])
              }
            }
            if (!format.test(element)) {
              this.placeholder.push(element)
              const fields = this.fb.group({
                [element]: []
              })
            } else {
              mail_flag = true;
            }
          });
        }
        if (mail_flag == true) {
          this.splitted_items.forEach((element: any) => {
            var arr_Str = element.split(/[<>]/);
            arr_Str.forEach((z: any) => {
              this.values_b.push(z);
            });
            this.values_b.forEach((y: any) => {
              var regex = /^[A-Za-z0-9&-; ]+$/
              var isValid = regex.test(y);
              if (isValid && y != '' && y != ';') {
                let t = y.substring(0, y.indexOf(" &"));
                t = (t == '') ? y : t;
                let flag;
                flag = new RegExp("\\b" + "Stuur" + "\\b").test(t);
                (flag == true) ? this.dropdown_values.push(t) : '';

                flag = new RegExp("\\b" + "Informeer" + "\\b").test(t);
                (flag == true) ? this.dropdown_values.push(t) : '';
              }
            });
          });
        }
      }
      else if (!item.extraLevel.includes('mailto') && item.extraLevel.includes(';') || item.extraLevel.includes('|') && !item.extraLevel.includes('ur')) {
        let temp = item.extraLevel.split('|');
        this.firstIndexvalue = temp;
        let obj: any = []
        temp.forEach((element: any, index: any, object: any) => {
          if (element.includes("|")) {
            this.dropdown_values = element.split("|");
            this.defaultDropdown = this.dropdown_values
          }
          if (element.includes(";")) {
            this.dropdown_values = element.split(";");
            obj.push(element.split(";"))
            this.defaultDropdown = this.dropdown_values
          }
          if (!element.includes(";") && !element.includes("|")) {
            this.splitted_items.push(element)
          }
        });
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            this.placeholder.push(element)
            const fields = this.fb.group({
              [element]: []
            })
          });
        }

      }
      else if (!item.extraLevel.includes('mailto') && item.extraLevel.includes(';') || (item.extraLevel.includes('|') && item.extraLevel.includes('ur'))) {
        let mail_flag;
        this.splitted_items = item.extraLevel.split('|');
        this.firstIndexvalue = this.splitted_items
        var format = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g;
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            if (!format.test(element)) {
              this.placeholder.push(element)
              const fields = this.fb.group({
                [element]: []
              })
            } else {
              mail_flag = true;
            }
          });
        }
        if (mail_flag) {
          let url: any;
          this.splitted_items.forEach((element: any) => {
            var arr_Str = element.split(':');
            url = arr_Str[1]
          })
          this.url = atob(url);;
        }
      }
      else if (!item.extraLevel.includes('mailto') && !item.extraLevel.includes(';') && item.extraLevel.includes('|')) {
        this.splitted_items = item.extraLevel.split('|');
        this.firstIndexvalue = this.splitted_items
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            this.placeholder.push(element);
            const fields = this.fb.group({
              [element]: []
            })
          });
        }
      }
      else if (!item.extraLevel.includes('mailto') && !item.extraLevel.includes(';') && !(item.extraLevel.includes('|') && item.extraLevel.includes('ur'))) {
        let mail_flag;
        if (item.extraLevel.includes('/')) {
          let str = item.extraLevel.split('/');
          this.splitted_items = str;
          this.firstIndexvalue = this.splitted_items;
          var format = /[~`!#$%\^&*+=\[\\]\\';,{}|\\":<>\?]/g;
          if (this.splitted_items?.length > 0) {
            this.splitted_items.forEach((element: any) => {
              if (!format.test(element)) {
                this.placeholder.push(element)
                const fields = this.fb.group({
                  [element]: []
                })
              } else {
                mail_flag = true;
              }
            });
          }
          if (mail_flag) {
            let url: any;
            this.splitted_items.forEach((element: any) => {
              var arr_Str = element.split(':');
              url = arr_Str[1]
            })
            this.url = atob(url);
          }
        }
        else {
          this.splitted_items = [item.extraLevel];
          this.firstIndexvalue = this.splitted_items
          var format = /[~`!#$%\^&*+=\[\\]\\';,{}|\\":<>\?]/g;
          if (this.splitted_items?.length > 0) {
            this.splitted_items.forEach((element: any) => {
              if (!format.test(element)) {
                this.placeholder.push(element)
                const fields = this.fb.group({
                  [element]: []
                })
              } else {
                mail_flag = true;
              }
            });
          }
          if (mail_flag) {
            let url: any;
            this.splitted_items.forEach((element: any) => {
              var arr_Str = element.split(':');
              url = arr_Str[1]
            })
            this.url = atob(url);
          }
        }
      } else {
        if (!item.extraLevel.includes('mailto') && !item.extraLevel.includes(';') && !item.extraLevel.includes('|')) {
          this.placeholder.push(item.extraLevel);
          const fields = this.fb.group({
            [item.extraLevel]: []
          })
        }
      }

      for (let item in this.placeholder) {
        this.popupform.addControl(this.placeholder[item], new FormControl('', Validators.required));
      }
      this.openmd(this.popupmodal);
    }
  }

  async set_flag() {
    this.navigate();
    let data: any = localStorage.getItem("active_order")
    data = JSON.parse(data as any);
    this.active_order = { orderID: data.orderID, mapping_field: data.mapping_field };
    let greendata: any = localStorage.getItem("green_eventdata");
    (greendata) ? greendata = JSON.parse(greendata as any) : greendata = undefined;
    let eventDescp: any = localStorage.getItem("green_subcat");
    eventDescp = JSON.parse(eventDescp);
    (eventDescp) ? eventDescp : eventDescp = "working on the order";
    if (this.role == 'office') {
      if (data) {
        await this.LandingPageComponent.stop(data, 'red', false);
        (greendata) ? await this.LandingPageComponent.stop(greendata, 'red', false) : '';

        (greendata) ? await this.createorder('red', this.SelectedCategory, greendata,) : '';

        (data && greendata) ? await this.LandingPageComponent.createorder('green', eventDescp, data) : '';

        (data && greendata) ? await this.createorder('red', this.SelectedCategory, data) : '';
        (data && !greendata) ? await this.createorder("red", this.SelectedCategory, data) : '';


      } else {
        (greendata) ? await this.LandingPageComponent.stop(greendata, 'red', false) : '';
        this.LandingPageComponent.active_order = greendata;

        await this.createorder("red", this.SelectedCategory, greendata);


        let order: any = localStorage.getItem('darkblueorder');
        order = JSON.parse(order);
        this.apiservice.addorder(order).subscribe(async (res: any) => {
          if (res.response == 'success') {
            this.LandingPageComponent.navigate_cal();
            this.modalService.dismissAll();
            let data = {
              eventID: res.eventID,
              updateFields: [
                {
                  remark: order.remark
                }
              ]
            }
            this.apiservice.addformfield(data).subscribe(async (res: any) => {
            })
            //this.LandingPageComponent.getorders(this.currentuser.userID, this.workstationid)
          }
        });

      }
    } else {
      if (environment.Deactivate_timer == true) {
        (data) ? await this.DashboardComponent.stop(data, 'red', false) : '';
        (greendata) ? (await this.DashboardComponent.stop(greendata, 'red', false)) : '';
        (greendata) ? await this.createorder("red", this.SelectedCategory, greendata) : '';


        (data && greendata) ? await this.DashboardComponent.createOrder("green", eventDescp, data, '') : '';

        (data && !greendata) ? await this.createorder("red", this.SelectedCategory, data) : '';


        //  (this.checklist_flag == 'afterGreen') ?
        //  this.getChecklistItems(this.currentuser?.userID, data?.orderID, this.checklist_flag) : '';
      }
    }
    localStorage.removeItem("green_eventdata");
    localStorage.removeItem('darkblueorder');
  }


  async createorder(flag: any, item: any, order: any) {
    var unixtimestamp = Math.floor((new Date).getTime() / 1000);
    let datas = {
      orderNr: (this.DashboardComponent?.active_order) ? this.DashboardComponent.active_order.orderID : this.LandingPageComponent.active_order.orderID,
      customerRef: "",
      orderkey: (this.DashboardComponent?.active_order?.mapping_field) ? this.DashboardComponent.active_order?.mapping_field : this.LandingPageComponent.active_order?.mapping_field ? this.LandingPageComponent.active_order?.mapping_field : '',
      userID: this.currentuser.userID,
      actionType: flag,
      eventDescription: item.text,
      actionDetail: item.value,
      workstationID: this.workstationid,
      monitorStartTime: unixtimestamp
    };
    this.apiservice.addorder(datas).subscribe(
      async (res: any) => {
        let payload = {
          debugMode: true,
          workstationID: this.workstationid,
          data: {
            type: 'startEvent',
            time: new Date().toISOString()
          }
        };
        await this.apiservice.errorLog(payload);
        res['orderDescription'] = order?.orderDescription;
        (this.role === 'office') ? '' : res['mapping_field'] = this.DashboardComponent?.active_order?.mapping_field;
        this.RedEventID = res.eventID;
        this.DashboardComponent.disabledOrder = false;
        if (res.dynamicExtraLevel) {
          this.setAutoPopupForm(res.dynamicExtraLevel);
        } else {
          this.setAutoPopupForm(null);
        }
        (this.role === 'office') ? (this.LandingPageComponent.getorders(this.LandingPageComponent.currentuser.userID, this.workstationid, false), this.LandingPageComponent.getnotes()) :
          (this.DashboardComponent.getOrders(this.DashboardComponent.currentuser.userID, this.workstationid, res, false),
            this.DashboardComponent.getNotes());
        // (Object.keys(this.popupform?.value).length > 0 || this.popupform?.value.mail != '') ? this.PostFormFields() : this.navigate();
      }, (error: any) => {
        this.toastrService.error('Something went wrong!', 'Error!');
      })
  }


  PostFormFields() {
    let data: any = {
      eventID: this.RedEventID,
      updateFields: [],
    };

    for (const [key, value] of Object.entries(this.popupform.value)) {
      if ((value as any).trim() != '') {
        data.updateFields.push({ [key]: value })
      }
    }
    this.apiservice.addformfield(data).subscribe(
      (res: any) => {
        if (res.response == 'success') {
        } else {
          this.toastrService.error('Not added!', 'Error!');
        }
      }, (error) => {
      })
  }


  async submitdropdown() {

    if (this.popupform.valid || +this.SelectedCategory?.extraLevel_mandatory === 0) {
      this.modalService.dismissAll();
      (Object.keys(this.popupform?.value).length > 0 || this.popupform?.value.mail != '') ? this.PostFormFields() : this.navigate();
    }
    else {
      this.toastrService.error('Please select any value!', 'Error!');
    }



  }



  open(content: any, item: any) {
    if (item.preventSkipExtraLevel == '1') {
      this.config.backdrop = 'static';
      this.config.keyboard = false;
      this.hidebutton = false;
    } else {
      this.config.backdrop = true;
      this.config.keyboard = true;
      this.hidebutton = true;
    }
    this.eventDesc = item;
    this.dropdown_values = [];
    if (item.extraLevel != null) {
      let temp = item.extraLevel.split('mailto');
      if (temp[0] == 'Instructie|') {
        temp.forEach((element: any) => {
          var arr_Str = element.split(/[<>]/);
          arr_Str.forEach((z: any) => {
            this.values_b.push(z);
          });
        });
        this.values_b.forEach((y: any) => {
          var regex = /^[A-Za-z0-9&; ]+$/
          var isValid = regex.test(y);
          if (isValid && y != '' && y != ';') {
            let t = y.substring(0, y.indexOf(" &"));
            t = (t == '') ? y : t
            this.values.push(t)
          }
        });
        if (this.values.length > 1) {
          for (let k = 0; k < this.values.length - 1; k++) {
            if (this.values[k] && k + 1 <= this.values.length - 1) {
              this.dropdown_values.push(this.values[k] + ' & ' + this.values[k + 1]);
              k = k + 1;
            }
          }
        } else {
          this.dropdown_values = this.values;
          this.defaultDropdown = this.dropdown_values
        }
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
      else if (!item.extraLevel.includes(";")) {
        this.openmd(this.contentRef);
        this.placeholder = item.extraLevel;
      } else {
        let temp = item.extraLevel.split(';');

        temp.forEach((element: any) => {
          this.dropdown_values.push(element);
          this.defaultDropdown = this.dropdown_values
        });
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    } else {
      this.setflag("red", this.eventDesc)
    }

  }

  openmd(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  navigate() {
    (this.role === 'office') ? this.LandingPageComponent.set_flag('screen', '') : this.DashboardComponent.set_flag('screen', '');
  }

  async setflag(datas: any, eventDesc: any) {
    if (this.role == 'office') {
      let data = localStorage.getItem("active_order")
      data = JSON.parse(data as any);
      let greendata = localStorage.getItem("green_eventdata");
      greendata = JSON.parse(greendata as any);
      if (data) {
        let eventDescp: any = localStorage.getItem("green_subcat");
        eventDescp = JSON.parse(eventDescp);
        (eventDescp) ? eventDescp : eventDescp = "working on the order";
        await this.LandingPageComponent.stop(data, 'red', false);
        (greendata) ? await this.LandingPageComponent.stop(greendata, 'red', false) : '';
        (greendata) ? await this.LandingPageComponent.createorder('red', eventDesc, greendata) : '';
        (data && greendata) ? await this.LandingPageComponent.createorder("green", eventDescp, data) : '';
        (data && !greendata) ? await this.LandingPageComponent.createorder("red", eventDesc, data) : '';
      } else {
        (greendata) ? await this.LandingPageComponent.stop(greendata, 'red', false) : '';
        this.LandingPageComponent.active_order = greendata;
        await this.LandingPageComponent.createorder("red", eventDesc, greendata);
        let order: any = localStorage.getItem('darkblueorder');
        order = JSON.parse(order);
        this.apiservice.addorder(order).subscribe(async (res: any) => {
          if (res.response == 'success') {
            this.LandingPageComponent.navigate_cal();
            this.modalService.dismissAll();
            let data = {
              eventID: res.eventID,
              updateFields: [
                {
                  remark: order.remark
                }
              ]
            }
            this.apiservice.addformfield(data).subscribe(async (res: any) => {
            })
            this.LandingPageComponent.getorders(this.currentuser.userID, this.workstationid, false)
          }
        });
        // localStorage.removeItem("green_eventdata");
        localStorage.removeItem('darkblueorder');
      }
    } else {
      if (environment.Deactivate_timer == true) {
        let eventDescp: any = localStorage.getItem("green_subcat");
        eventDescp = JSON.parse(eventDescp);
        (eventDescp) ? eventDescp : eventDescp = "working on the order";
        let greendata = localStorage.getItem("green_eventdata");
        greendata = JSON.parse(greendata as any);
        let data = localStorage.getItem("active_order")
        data = JSON.parse(data as any);
        (greendata) ? (await this.DashboardComponent.stop(greendata, 'red', false)) : '';
        (await this.DashboardComponent.stop(data, 'red', false));
        (greendata) ? (await this.DashboardComponent.createOrder("red", eventDesc, greendata, '')) : '';
        (data && greendata) ? (await this.DashboardComponent.createOrder("green", eventDescp, data, '')) : '';
        (data && !greendata) ? (await this.DashboardComponent.createOrder("red", eventDesc, data, '')) : '';
      }
    }
    localStorage.removeItem("green_eventdata");
    localStorage.removeItem("green_subcat");
    this.navigate();
  }
}
