import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { HammerModule } from "../../node_modules/@angular/platform-browser";
registerLocaleData(localeIt);
import { DownTimeComponent } from 'src/basemodule/down-time/down-time.component';
import { UnplannedDownTimeComponent } from '../basemodule/unplanned-down-time/unplanned-down-time.component';
import { ProjectListComponent } from 'src/basemodule/project-list/project-list.component';
import { ReportsComponent } from 'src/basemodule/reports/reports.component';
import { WorkingHoursComponent } from 'src/basemodule/working-hours/working-hours.component';
import { WorkingOnComponent } from 'src/basemodule/working-on/working-on.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FooterComponent } from 'src/footer/footer.component';
import { HeaderComponent } from 'src/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TranslateModule } from '@ngx-translate/core';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DayPilotModule } from 'daypilot-pro-angular';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { OrderintakeFlowComponent } from '../basemodule/orderintake-flow/orderintake-flow.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ChecklistModalComponent } from '../basemodule/checklist-modal/checklist-modal.component';
import { NgxTimeMaskModule } from 'ngx-time-mask';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatDialogModule } from "@angular/material/dialog";
import { MatKeyboardModule } from 'angular-onscreen-material-keyboard';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MinmaxDirective } from '../directives/minmax.directive';
import { QRCodeModule } from 'angularx-qrcode';
let modules = [MatFormFieldModule, MatInputModule, Ng2SearchPipeModule, NgxTimeMaskModule, InputMaskModule];

@NgModule({
  declarations: [
    MinmaxDirective,
    OrderintakeFlowComponent,
    WorkingOnComponent,
    HeaderComponent,
    FooterComponent,
    DownTimeComponent,
    UnplannedDownTimeComponent,
    ProjectListComponent,
    ReportsComponent,
    WorkingHoursComponent,
    ChecklistModalComponent,

  ],
  exports: [
    ...modules,
    MinmaxDirective,
    MatKeyboardModule,
    OrderintakeFlowComponent,
    MatDatepickerModule,
    BsDatepickerModule,
    InputMaskModule,
    WorkingOnComponent,
    PdfViewerModule,
    HeaderComponent,
    FooterComponent,
    DownTimeComponent,
    UnplannedDownTimeComponent,
    ProjectListComponent,
    ReportsComponent,
    WorkingHoursComponent,
    TranslateModule,
    DayPilotModule,
    ToastrModule,
    ChecklistModalComponent,
    HammerModule,
    MatSelectModule,
    MatCheckboxModule,
    QRCodeModule
  ],
  imports: [
    ...modules,
    MatKeyboardModule,
    MatDatepickerModule,
    BsDatepickerModule.forRoot(),
    InputMaskModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    PdfViewerModule,
    TranslateModule,
    NgxSpinnerModule,
    NgxUiLoaderModule,
    DayPilotModule,
    ToastrModule,
    MatDialogModule,
    HammerModule,
    MatSelectModule,
    MatCheckboxModule,
    QRCodeModule
  ],
})
export class SharedModule {
}
