import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Userlist } from '../models/userlist';
import { ordersResponse } from '../models/orders';
import { LoggedInUser } from '../models/loggedinuser';
import { Order } from '../models/order'
import { ERPCustomers, ERPProducts } from '../models/erpcustomers';
import { Checklist, createResponse } from '../models/checklist';
import { instructionList } from '../models/instructionlist';
import { calendarEvent } from '../models/calendarevent';
import { calendarEventList } from '../models/calendareventlist';
import { customizing } from '../models/customizing';
import { categories } from '../models/categories';
import { activeUsers } from '../models/activeusers';
import { smartMonitorLogin } from '../models/smartmonitorlogin';
import { notes } from '../models/notes';

import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  url = environment.baseUrl;
  socket: Socket;
  private withoutintercept: HttpClient;

  constructor(private http: HttpClient, private handler: HttpBackend) {
    this.withoutintercept = new HttpClient(handler);
    this.socket = io(environment.socketUrl);
    this.socket.on('connect', () => {
      console.log('Connected to socket');
    });
  }


  getERPOrdersResync(customerID: string | null): Observable<any> {
    return new Observable(observer => {
      if (!customerID || customerID === '') {
        observer.error('Customer ID is null');
        observer.complete();
        return;
      }
      this.socket.on(`event_broadcast_customer_${customerID}`, (data: any) => {
        observer.next(data);
      });
    });
  }

  // login
  doLogin(login: any): Observable<LoggedInUser> {
    return this.http.post<LoggedInUser>(`${this.url}/login`, login);
  }

  LoginMonitor(login: any): Observable<smartMonitorLogin> {
    return this.http.post<smartMonitorLogin>(`${this.url}/login-monitor`, login);
  }

  getcustomers(): Observable<ERPCustomers> {
    return this.http.get<ERPCustomers>(`${this.url}/ERPCustomers`);
  }

  getuserlist(id: any): Observable<activeUsers> {
    return this.http.get<activeUsers>(`${this.url}/UsersLoggedIn`);
  }

  getAvailableUsersList(userids: any, id: any): Observable<Userlist> {
    return this.http.get<Userlist>(`${this.url}/Users` + '?' + "userids=" + userids + "&workstationID=" + id);
  }

  getorder(userid: any, workstationID: any): Observable<ordersResponse[]> {
    return this.http.get<ordersResponse[]>(`${this.url}/Events` + '?' + "fkWorkstationID=" + workstationID + "&" + "userID=" + userid);
  }

  addorder(data: any): Observable<Order> {
    return this.http.post<Order>(`${this.url}/Order`, data);
  }

  stopevent(data: any) {
    return this.http.put(`${this.url}/Order`, data);
  }

  getcategories(userid: any): Observable<categories> {
    return this.http.get<categories>(`${this.url}/Categories` + '?' + "customerID=" + userid);
  }

  addformfield(data: any) {
    return this.http.post(`${this.url}/FormFields`, data);
  }

  createalert(data: any) {
    return this.http.post(`${this.url}/Alert`, data);
  }

  adduser(data: any) {
    return this.http.post(`${this.url}/UsersLoggedIn`, data)
  }

  deleteuser(data: any) {
    return this.http.put(`${this.url}/UsersLoggedIn`, data)
  }

  geterp(): Observable<any> {
    return this.http.get(`${this.url}/ERPOrders`);
  }

  getattachment(data: any, workstationid: any) {
    let orderId = data.orderID ? data.orderID : ''
    let orderkey = data.mapping_field ? data.mapping_field : ''
    return this.http.get(`${this.url}/Attachments` + '?' + "orderID=" + orderId + "&" + "orderkey=" + orderkey + "&" + "fkWorkstationID=" + workstationid);
  }

  getnotes(orderID: any): Observable<notes[]> {
    return this.http.get<notes[]>(`${this.url}/Notes` + '?' + "orderID=" + orderID);
  }

  getgreenactivities(): Observable<customizing> {
    return this.http.get<customizing>(`${this.url}/Customizing`);
  }

  changeLanguage(data: any): Observable<createResponse> {
    return this.http.put<createResponse>(`${this.url}/Users`, data);
  }

  getCalendar(userID: any): Observable<calendarEventList> {
    return this.http.get<calendarEventList>(`${this.url}/Calendar` + '?userID=' + userID + "&numberOfDaysHistory=");
  }

  create_event(data: any): Observable<calendarEvent> {
    return this.http.post<calendarEvent>(`${this.url}/Calendar`, data);
  }

  delete_event(data: any): Observable<createResponse> {
    return this.http.put<createResponse>(`${this.url}/Calendar`, data);
  }

  SetTimerForYellow(data: any) {
    return this.http.post(`${this.url}/TimerForYellowEvent`, data);
  }

  ForgotPassword(data: any): Observable<createResponse> {
    return this.http.post<createResponse>(`${this.url}/password-forget`, data);
  }

  syncingOrder(): Observable<ERPCustomers> {
    return this.http.get<ERPCustomers>(`${this.url}/ERPSync`);
  }

  getERPProductsByCustomerId(customerInfo: { customer: any }): Observable<ERPProducts> {
    return this.http.post<ERPProducts>(`${this.url}/ERPProducts`, customerInfo);
  }

  getChecklist(userID: string, flag: string, orderID: string, orderkey: string): Observable<Checklist> {
    return this.http.get<Checklist>(`${this.url}/Checklist` + '?userID=' + userID + '&processStep=' + flag + '&orderID=' + orderID + '&orderkey=' + orderkey);
  }

  getInstructions(orderID: string, orderkey: string): Observable<instructionList> {
    return this.http.get<instructionList>(`${this.url}/Instructions` + '?orderID=' + orderID + '&orderkey=' + orderkey);
  }
  postInstructions(data: any): Observable<any> {
    return this.http.post(`${this.url}/Instructions`, data);
  }

  createERPOorder(info: any) {
    return this.http.post(`${this.url}/ERPOrder`, info);
  }

  createCheckList(info: any): Observable<createResponse> {
    return this.http.post<createResponse>(`${this.url}/Checklist`, info);
  }

  deleteERPOorder(info: any) {
    return this.http.put(`${this.url}/ERPOrder`, info);
  }

  getPDFFile(info: any) {
    return this.http.post(`${this.url}/IJBweeklyPDF`, info);
  }

  addQuantity(info: any) {
    return this.http.post(`${this.url}/Print`, info);
  }
  aaddQuantity() {
    return this.http.get(`${this.url}/Print`);
  }
  localhostPost(info: any) {
    return this.http.post(`http://localhost:8000`, info);
  }

  updateERProductsQTY(info: any) {
    return this.http.put(`${this.url}/ERPProducts`, info);
  }

  updateWorkLocation(info: any) {
    return this.http.post(`${this.url}/CalendarWorkLocation`, info);
  }

  authentication(info: any) {
    return this.http.post(`${this.url}/2FA`, info);
  }

  validateAutoLoginToken(info: any): Observable<LoggedInUser> {
    return this.http.post<LoggedInUser>(`${this.url}/validateAutoLoginToken`, info);
  }

  public async errorLog(data: any) {
    this.withoutintercept.post(`${this.url}/errorLog`, data).subscribe((res: any) => {
    }, (err) => {
      console.log(err)
    });
  }
  public validateprojectnumber(data: any): Observable<any> {
    return this.http.post(`${this.url}/IJB-validate-projectnumber`, data)
  }


}

