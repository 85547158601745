<div class="row mb-4 green titleBar">
    <div class="col-md-3 text-start"><a (click)="navigate()" (touchend)="navigate()"><i class="fa fa-arrow-left"></i>&nbsp;&nbsp;{{'HOME.BACKBUTTON' | translate}}</a></div>
    <div class="col-md-9 text-end">{{ 'HOME.GREEN_CATEGORY_QUESTION' | translate }}</div>
</div>
<div class="row" *ngFor="let item of sorted_categories">
  <p class="fw-bolder text-start" >{{item.group}}</p>
    <div class="col-md-6 mx-2" *ngFor="let items of item.cat"  (click)="setFormFields(items); $event.stopPropagation();">
        <div class="row mb-2  actionButton action" >
            <div class="col my-auto text-start">
                <div class="row">
                    <span class="button-text-small" *ngIf="items.text != null && items.hidden != '1' && items.languageKey == null  || items.languageKey == ''">{{items.text}}</span>
                    <span class="button-text-small" *ngIf="items.hidden != '1' && items.languageKey != null && items.languageKey != ''">{{ "HOME."+items.languageKey | translate }}</span>
                    <span class="button-text-small" *ngIf="items.hidden != '1' && items.text == null && items.languageKey == null  && items.languageKey != ''">null</span>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <hr>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
      <h2 class="modal-title" id="modal-basic-title">
        {{ "HOME.ACTIVITYTITTLE" | translate }}
      </h2>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click'); clear_greendata()"
      ></button>
    </div>
    <div class="modal-body">
      <p *ngIf="role == 'office'">
        {{ "HOME.ACTIVITYCONTENT_OFFICE" | translate }}
      </p>
      <p  *ngIf="role == 'office'">
        {{ "HOME.ACTIVITY_QUES_OFFICE" | translate }} {{ green_eventdata?.orderID }}
        {{ "HOME.ON_WORKSTATION" | translate }} {{ name }} doen?
      </p>
      <p *ngIf="role != 'office'">
        {{ "HOME.ACTIVITYCONTENT" | translate }}
      </p>
      <p  *ngIf="role != 'office'">
        {{ "HOME.ACTIVITY_QUES" | translate }} {{ green_eventdata.orderID }}
        {{ "HOME.ON_WORKSTATION" | translate }} {{ name }} ?
      </p>
      <form [formGroup]="activityForm" novalidate>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="event"
            formControlName="event"
            value="close"
            id="flexRadioDefault1"
          />
          <label class="form-check-label" for="flexRadioDefault1">
            {{ "HOME.CLOSEBUTTON" | translate }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="event"
            formControlName="event"
            value="yellow"
            id="flexRadioDefault2"
          />
          <label class="form-check-label" for="flexRadioDefault2">
            {{ "HOME.PUT_YELLOW" | translate }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="event"
            formControlName="event"
            value="red"
            id="flexRadioDefault3"
          />
          <label class="form-check-label" for="flexRadioDefault3">
            {{ "HOME.PUT_RED" | translate }}
          </label>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modal.close('Save click'); clear_greendata()"
      >
        {{ "HOME.CANCELBUTTON" | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="onSubmit_event()">
        {{ "HOME.CONFIRMBUTTON" | translate }}
      </button>
    </div>
  </ng-template>

<ng-template #textboxmodal let-modal >
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title"> {{'HOME.ADDITIONALINFOTITTLE' | translate}} </h2>
        <button  type="button" *ngIf="eventDesc.preventSkipExtraLevel != '1'" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="dropdownform"  (keyup.enter)="submit_dropdown()" novalidate>
          <div class="mb-3">
            <select class="form-select form-select-lg" formControlName="additional_info" [(ngModel)]="dropdown_values[0]"
                aria-label=".form-select-sm example">
                <option *ngFor="let item of dropdown_values" value={{item}} >{{item}}</option>
            </select>
        </div>
        </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="submit_dropdown()">{{'HOME.CONFIRMBUTTON' | translate}}</button>
        <button  type="button" *ngIf="eventDesc.preventSkipExtraLevel != '1'" class="btn btn-success reset" (click)="modal.close('Save click')">{{'HOME.CANCELBUTTON' | translate}}</button>
    </div>
</ng-template>

<app-checklist-modal #instructions [checkListItems]="checkListItems" [checkListFormGroup]="checkListFormGroup" [orderID]="active_order?.orderID"></app-checklist-modal>

<ng-template #popupformmodal let-modal>
  <div class="modal-header">
      <h2 class="modal-title" id="modal-basic-title"> {{'HOME.ADDITIONALINFOTITTLE' | translate}} </h2>
      <button type="button" *ngIf="SelectedCategory.preventSkipExtraLevel != '1'" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
      <form [formGroup]="popupform" (keyup.enter)="submitdropdown()" novalidate>
              <div *ngFor="let a of placeholder;let i=index" >
                  <div class="mb-3">
                      <div class="form-group">
                          <input type="text" class="form-control" [formControlName]="a"
                              placeholder="{{a}}">
                      </div>
                  </div>
              </div>
          <div class="mb-3" *ngIf="dropdown_values?.length > 0">
              <select class="form-select form-select-lg" formControlName="mail" [(ngModel)]="dropdown_values[0]" aria-label=".form-select-sm example" >
                  <option *ngFor="let item of dropdown_values" value={{item}}>{{item}}</option>
              </select>
          </div>
      </form>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="submitdropdown()">{{'HOME.CONFIRMBUTTON' | translate}}</button>
      <button type="button" *ngIf="SelectedCategory.preventSkipExtraLevel != '1'" class="btn btn-success reset" (click)="modal.close('Save click')">{{'HOME.CANCELBUTTON' | translate}}</button>
  </div>
</ng-template>

